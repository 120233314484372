/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */


import Flickity from "flickity";
import 'flickity/dist/flickity.min.css';
import 'flickity-fade';
import 'flickity-fade/flickity-fade.css';
// import utils from 'fizzy-ui-utils';

import LazyLoad from "vanilla-lazyload";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Scrollbar from 'smooth-scrollbar';

import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import "locomotive-scroll/bundled/locomotive-scroll.css";
import LocomotiveScroll from 'locomotive-scroll';

const locomotiveScroll = new LocomotiveScroll({
    lenisOptions: {
        wrapper: window,
        content: document.documentElement,
        lerp: 0.1,
        duration: 1.2,
        orientation: 'vertical',
        gestureOrientation: 'vertical',
        smoothWheel: true,
        smoothTouch: false,
        wheelMultiplier: 1,
        touchMultiplier: 2,
        normalizeWheel: true,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    },
});

var myLazyLoad = new LazyLoad();

let mm = gsap.matchMedia();

// add this code
Flickity.create._createResizeClass = function() {
    this.element.classList.add('flickity-resize');
};

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
    this.element.classList.remove('flickity-resize');
    resize.call( this );
    this.element.classList.add('flickity-resize');
};

// Media Enter / Leave - Play / Pause
let mediaOnEnter = (media) => {
    var videoElement = media.querySelector(".video--item");
    var videoSrc = videoElement.dataset.src;
  
    if(videoElement.src == null || videoElement.src === ''){
      videoElement.src = videoSrc;
      videoElement.load();
      videoElement.addEventListener("loadeddata", function(){
        media.classList.add('is-playing');
        videoElement.play();
      });
    } else {
      media.classList.add('is-playing');
      videoElement.play();
    }
  }
  let mediaOnLeave = (media) => {
    var videoElement = media.querySelector(".video--item");
  
    media.classList.remove('is-playing');
    videoElement.pause();
  }

/* CONTENT 
LOADED */
document.addEventListener("DOMContentLoaded", () => {

    if(document.querySelector(".media")){
        var medias = document.querySelectorAll(".media");
        medias.forEach((media, i) => {
            if(media.querySelector(".video--item")){
                var mediaTl = gsap.timeline({
                    scrollTrigger: {
                      trigger: media,
                      start: "top 80%",
                      end: "bottom 10%",
                    //   markers: true,
                      onEnter: function(){
                        mediaOnEnter(media)
                      },
                      onEnterBack: function(){
                        mediaOnEnter(media)
                      },
                      onLeave: function(){
                        mediaOnLeave(media)
                      },
                      onLeaveBack: function(){
                        mediaOnLeave(media)
                      }
                    }
                });
            }
        })
    }

    if(document.querySelector(".PromiseCarousel")){
        var promiseContainer = document.querySelector(".Promise");
        var promiseCarousel = promiseContainer.querySelector('.PromiseCarousel');
        var flkty = new Flickity( promiseCarousel, {
            prevNextButtons: true,
            pageDots: true,
            fade: true,
            watchCSS: true,
            arrowShape: 'M55.5,64c-0.8,0-1.5-0.3-2.1-0.9l-11-11c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1l11-11 c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2L48.7,50l8.9,8.9c1.2,1.2,1.2,3.1,0,4.2C57,63.7,56.3,64,55.5,64z'
        });
    }

    if(document.querySelector(".accordions")){
        let accordions = document.querySelectorAll(".accordions .acc");

        let openAccordion = (accordion) => {
            let accContent = accordion.querySelector(".acc-cont");
            accordion.classList.add("active");
            accContent.style.maxHeight = accContent.scrollHeight + "px";
        };

        let closeAccordion = (accordion) => {
            let accContent = accordion.querySelector(".acc-cont");
            accordion.classList.remove("active");
            accContent.style.maxHeight = null;
        };

        accordions.forEach((accordion) => {
            let accTitle = accordion.querySelector(".acc-title");
            let accContent = accordion.querySelector(".acc-cont");

            accTitle.onclick = () => {
                if (accContent.style.maxHeight) {
                    closeAccordion(accordion);
                } else {
                    accordions.forEach((accordion) => closeAccordion(accordion));
                    openAccordion(accordion);
                }
            };
        });
    }

    gsap.to(document.querySelector(".imageSun"), {
        scale: (i, el) => 1. + gsap.getProperty(el, "--progress"),
    });

    // Benessere Dialog
    var dialogBenessereEl = document.getElementById('benessere-dialog');
    var dialogBenessere = new A11yDialog(dialogBenessereEl);

    if (window.matchMedia("(min-width: 640px)").matches) {
        dialogBenessere
            .on('show', () => disableBodyScroll(dialogBenessereEl))
            .on('hide', () => enableBodyScroll(dialogBenessereEl))
    }
    
    var dialogBenessereButton = document.querySelector(".benessere-dialog-button");
    dialogBenessereButton.onclick = (event) => {
        event.preventDefault();
                
        Scrollbar.init(document.querySelector('.dialog-scroller'), {
            alwaysShowTracks: true
        });

        dialogBenessere.show()
    }

    // Benessere Allevamento Dialog
    var dialogBenessereAllevamentoEl = document.getElementById('benessere-allevamento-dialog');
    var dialogBenessereAllevamento = new A11yDialog(dialogBenessereAllevamentoEl);

    if (window.matchMedia("(min-width: 640px)").matches) {
        dialogBenessereAllevamento
            .on('show', () => disableBodyScroll(dialogBenessereAllevamentoEl))
            .on('hide', () => enableBodyScroll(dialogBenessereAllevamentoEl))
    }

    // Benessere Trasporto Dialog
    var dialogBenessereTrasportoEl = document.getElementById('benessere-trasporto-dialog');
    var dialogBenessereTrasporto = new A11yDialog(dialogBenessereTrasportoEl);

    if (window.matchMedia("(min-width: 640px)").matches) {
        dialogBenessereTrasporto
            .on('show', () => disableBodyScroll(dialogBenessereTrasportoEl))
            .on('hide', () => enableBodyScroll(dialogBenessereTrasportoEl))
    }

    // Benessere Macello Dialog
    var dialogBenessereMacelloEl = document.getElementById('benessere-macello-dialog');
    var dialogBenessereMacello = new A11yDialog(dialogBenessereMacelloEl);

    if (window.matchMedia("(min-width: 640px)").matches) {
        dialogBenessereMacello
            .on('show', () => disableBodyScroll(dialogBenessereMacelloEl))
            .on('hide', () => enableBodyScroll(dialogBenessereMacelloEl))
    }
    
    // Benessere Farmaco Dialog
    var dialogBenessereFarmacoEl = document.getElementById('benessere-farmaco-dialog');
    var dialogBenessereFarmaco = new A11yDialog(dialogBenessereFarmacoEl);

    if (window.matchMedia("(min-width: 640px)").matches) {
        dialogBenessereFarmaco
            .on('show', () => disableBodyScroll(dialogBenessereFarmacoEl))
            .on('hide', () => enableBodyScroll(dialogBenessereFarmacoEl))
    }
});
